import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

// ----------------------------------------------------------------------

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;

  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%" viewBox="0 0 1279.000000 1280.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd" 
          transform="translate(0.000000,500.000000) scale(0.100000,-0.100000)">
          <path d="M5413 7210 c-13 -5 -23 -11 -23 -14 0 -7 40 6 46 15 6 10 6 10 -23
-1z"/>
       <path d="M1680 3755 c0 -26 5 -45 11 -45 7 0 9 10 7 24 -4 20 -1 23 19 22 17
-1 23 -7 23 -23 0 -13 5 -23 10 -23 6 0 10 19 8 45 -2 25 -5 45 -7 45 -3 0
-13 -7 -23 -16 -16 -16 -18 -16 -24 0 -12 31 -24 16 -24 -29z"/>
<path d="M1805 3783 c4 -10 10 -30 13 -45 5 -32 22 -38 22 -8 0 12 7 32 16 45
9 14 12 25 6 25 -6 0 -15 -8 -21 -17 -9 -17 -10 -17 -16 0 -4 9 -12 17 -17 17
-6 0 -7 -8 -3 -17z"/>
<path d="M1910 3755 c0 -45 1 -46 28 -43 24 3 27 7 27 43 0 36 -3 40 -27 43
-27 3 -28 2 -28 -43z m40 27 c0 -13 -12 -22 -22 -16 -10 6 -1 24 13 24 5 0 9
-4 9 -8z m0 -42 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0
15 -4 15 -10z"/>
<path d="M2010 3755 c0 -44 1 -45 32 -45 17 0 28 3 24 8 -4 4 -13 7 -18 7 -6
0 -13 6 -15 13 -3 6 3 12 12 12 9 0 13 5 10 10 -4 6 -11 8 -16 5 -5 -4 -9 -1
-9 5 0 6 10 15 23 20 20 8 19 9 -10 9 -32 1 -33 0 -33 -44z"/>
<path d="M2123 3786 c10 -8 17 -26 17 -45 0 -17 4 -31 8 -31 5 0 7 14 4 31 -3
21 1 34 14 44 17 13 16 14 -21 14 -35 0 -38 -1 -22 -13z"/>
<path d="M2232 3761 c-16 -51 -15 -53 7 -39 15 9 23 9 35 -1 19 -16 20 -10 4
40 -6 22 -16 39 -23 39 -7 0 -17 -17 -23 -39z m24 -18 c-11 -11 -17 4 -9 23 7
18 8 18 11 1 2 -10 1 -21 -2 -24z"/>
<path d="M2330 3756 c0 -42 2 -46 24 -46 31 0 48 22 44 57 -2 23 -8 29 -35 31
-33 3 -33 3 -33 -42z m50 -1 c0 -13 -7 -25 -15 -29 -12 -4 -15 3 -15 29 0 26
3 33 15 29 8 -4 15 -16 15 -29z"/>
<path d="M2452 3761 c-16 -51 -15 -53 7 -39 15 9 23 9 35 -1 19 -16 20 -10 4
40 -6 22 -16 39 -23 39 -7 0 -17 -17 -23 -39z m25 -13 c-3 -7 -5 -2 -5 12 0
14 2 19 5 13 2 -7 2 -19 0 -25z"/>
<path d="M2561 3787 c10 -7 15 -25 14 -45 -1 -17 2 -32 7 -32 4 0 8 14 8 31 0
19 7 37 18 45 15 12 12 13 -23 13 -34 0 -37 -2 -24 -12z"/>
<path d="M2667 3755 c-9 -25 -12 -45 -6 -45 5 0 9 5 9 10 0 14 38 12 43 -2 11
-31 15 2 5 40 -14 54 -32 53 -51 -3z m29 -12 c-10 -10 -19 5 -10 18 6 11 8 11
12 0 2 -7 1 -15 -2 -18z"/>
<path d="M2843 3793 c-7 -2 -13 -20 -13 -38 0 -25 5 -35 20 -40 21 -7 40 0 40
15 0 4 -9 6 -20 3 -16 -4 -20 0 -20 22 0 23 4 26 21 22 13 -3 19 -1 16 6 -4
13 -26 18 -44 10z"/>
<path d="M2940 3775 c-13 -35 4 -67 33 -63 18 2 22 10 22 43 0 34 -3 40 -22
43 -17 3 -26 -3 -33 -23z m45 -21 c0 -10 -8 -20 -17 -22 -18 -3 -26 27 -11 42
12 11 28 0 28 -20z"/>
<path d="M3040 3755 c0 -25 5 -45 10 -45 6 0 10 10 10 23 0 16 6 22 25 23 21
2 24 -1 19 -22 -4 -15 -2 -24 5 -24 6 0 11 19 11 45 0 45 -12 60 -24 29 -6
-16 -8 -16 -24 0 -28 24 -32 20 -32 -29z"/>
<path d="M2770 3720 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
<path d="M2428 2213 c7 -3 16 -2 19 1 4 3 -2 6 -13 5 -11 0 -14 -3 -6 -6z"/>
<path d="M2491 2134 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M2971 2034 c0 -11 3 -14 6 -6 3 7 2 16 -1 19 -3 4 -6 -2 -5 -13z"/>
<path d="M1720 2035 c0 -5 5 -17 10 -25 5 -8 10 -10 10 -5 0 6 -5 17 -10 25
-5 8 -10 11 -10 5z"/>
<path d="M1780 1774 c0 -27 4 -43 10 -39 6 3 10 14 10 23 0 11 8 18 20 18 15
0 20 -7 20 -25 0 -40 12 -16 14 29 3 45 0 47 -26 24 -16 -16 -18 -16 -24 0
-12 31 -24 16 -24 -30z"/>
<path d="M1905 1803 c4 -10 10 -30 13 -45 2 -16 8 -28 12 -28 4 0 10 12 12 28
3 15 8 36 12 47 7 19 7 19 -7 3 -17 -22 -27 -23 -27 -3 0 8 -5 15 -11 15 -7 0
-8 -7 -4 -17z"/>
<path d="M2010 1775 c0 -45 1 -46 28 -43 24 3 27 7 27 43 0 36 -3 40 -27 43
-27 3 -28 2 -28 -43z m37 19 c-3 -3 -12 -4 -19 -1 -8 3 -5 6 6 6 11 1 17 -2
13 -5z m3 -34 c0 -5 -7 -10 -15 -10 -8 0 -15 5 -15 10 0 6 7 10 15 10 8 0 15
-4 15 -10z"/>
<path d="M2110 1775 c0 -43 1 -45 30 -45 17 0 30 5 30 10 0 6 -7 10 -16 8 -10
-2 -19 3 -21 10 -3 6 2 12 11 12 9 0 16 5 16 10 0 6 -7 10 -16 10 -8 0 -13 4
-9 10 3 5 12 7 20 3 8 -3 15 0 15 6 0 6 -13 11 -30 11 -29 0 -30 -2 -30 -45z"/>
<path d="M2222 1807 c9 -7 18 -29 21 -48 l4 -34 1 34 c1 19 10 40 20 47 15 12
12 13 -23 13 -34 0 -38 -1 -23 -12z"/>
<path d="M2331 1783 c-15 -52 -14 -55 8 -41 15 9 23 9 35 -1 19 -16 20 -9 5
42 -5 20 -16 37 -24 37 -8 0 -19 -17 -24 -37z m29 -9 c0 -8 -5 -12 -10 -9 -6
4 -8 11 -5 16 9 14 15 11 15 -7z"/>
<path d="M2430 1775 c0 -40 2 -45 23 -45 29 0 47 18 47 45 0 27 -18 45 -47 45
-21 0 -23 -5 -23 -45z m50 0 c0 -16 -6 -25 -15 -25 -9 0 -15 9 -15 25 0 16 6
25 15 25 9 0 15 -9 15 -25z"/>
<path d="M2552 1781 c-16 -51 -15 -53 7 -39 15 9 23 9 35 -1 19 -16 20 -10 4
40 -6 22 -16 39 -23 39 -7 0 -17 -17 -23 -39z m24 -18 c-11 -11 -17 4 -9 23 7
18 8 18 11 1 2 -10 1 -21 -2 -24z"/>
<path d="M2664 1805 c13 -10 17 -22 14 -40 -3 -14 -1 -28 3 -31 5 -3 9 9 9 27
0 19 7 37 18 45 15 12 12 13 -23 13 -37 0 -38 -1 -21 -14z"/>
<path d="M2767 1775 c-17 -49 -16 -51 13 -32 17 10 22 9 30 -3 16 -26 20 -4 7
35 -19 54 -32 54 -50 0z m29 -12 c-11 -11 -17 4 -9 23 7 18 8 18 11 1 2 -10 1
-21 -2 -24z"/>
<path d="M2934 1796 c-10 -40 -1 -61 25 -61 14 0 26 5 28 12 3 7 -3 9 -16 6
-17 -4 -21 -1 -21 22 0 24 3 27 27 20 23 -5 25 -4 14 9 -20 24 -50 20 -57 -8z"/>
<path d="M3040 1795 c-13 -35 4 -67 33 -63 18 2 22 10 22 43 0 34 -3 40 -22
43 -17 3 -26 -3 -33 -23z m45 -20 c0 -11 -8 -21 -17 -23 -14 -3 -18 3 -18 23
0 20 4 26 18 23 9 -2 17 -12 17 -23z"/>
<path d="M3148 1813 c-9 -19 -8 -72 1 -78 7 -4 11 3 11 17 0 17 6 24 20 24 14
0 20 -7 20 -23 0 -13 5 -23 10 -23 6 0 10 20 10 45 0 45 -12 60 -24 29 -6 -15
-8 -15 -25 0 -11 10 -21 14 -23 9z"/>
<path d="M2870 1740 c0 -5 5 -10 10 -10 6 0 10 5 10 10 0 6 -4 10 -10 10 -5 0
-10 -4 -10 -10z"/>
        </g>
      </svg>
    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>;
}
